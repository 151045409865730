<template>
    <h2> Office Policies </h2>
    <div>
        <p> <b> OFFICE POLICIES, EFFECTIVE May 1, 2014 </b></p>
        <p> <b>Office Hours: </b> Our medical office is open Monday – Friday, 8:15 am – 5:00 pm. Chiropractic hours are Monday,
            Wednesday and Friday 7:00 am – 4:00 pm; Tuesday and Thursday 7:00 am – 11:00 am. </p>
        <p> <b>Appointments: </b>We see patients by appointment only. Please arrive at least 5 minutes prior to your schedules
            appointment to allow us adequate time to properly check you in. If you have any changes to your demographic information (such as a change
            of address or insurance), please arrive 10 minutes prior to your scheduled time. <br />
            <b>Same Day Appointments:</b> We have a limited number of same day appointments available each day for sudden illness for existing patients.
            Please call before <strong>10:30 am</strong> if you wish to be seen the same-day. Appointments for more acute illnesses will be given priority. </p>
        <p> <b>Walk-in Appointments: </b> We are usually able to accommodate walk-in appointments. Patients who walk in and request
            a same-day appointment will be offered to wait or to come back at a later, scheduled time.</p>
        <p> <b>Call Backs: </b> Our nursing staff makes every attempt to return calls in a timely manner. Calls received after 4:00
            pm will not be returned until the following day. </p>
        <p> <b>After-hours, Emergencies, and Holidays:</b> If you have a life-threatening emergency, call <strong>911</strong> immediately. Avera
            St. Anthony’s Hospital phone number is 402 336-2611. Avera St. Anthony’s Emergency Department may be reached at 402 336-5111 and is available
            24 hours a day 7 days a week.</p>
        <p><b>Cancellations/Rescheduling:</b> Appointments for physical/wellness examinations are in high demand. If you cannot keep
            a wellness examination appointment, we require your cancellation notice no later than 24 hours prior to your scheduled appointment. </p>
        <p> <b>Running on time: </b> We know your schedule is busy and that your time is valuable. Our providers make every effort
            to keep on schedule. If you have been waiting more than 20 minutes and have not been advised of a delay, please speak to the receptionist
            to ensure that you have been properly checked in. Patients are seen in schedule order, with few exceptions. Patients arriving more than 10
            minutes after their scheduled appointment may be asked to reschedule. </p>
        <p> <b> Test Results: </b> Most lab results are available in 24-48 hours. Please follow your practitioner’s instructions to
            receive results either by calling our office or with a follow up appointment. Pap smear results are generally available in approximately one
            week. </p>
        <p> <b>Prescriptions and Refills:</b> The best time to get a prescription refill is at your appointment. If you need a refill,
            and your current prescription bottle indicates that your have refills remaining, please contact your pharmacy. Most prescriptions cannot be
            refilled for more than one year without an appointment for reevaluation by your medical provider. </p>
        <p><b><i>Narcotics:</i></b> We do not routinely prescribe narcotics for chronic pain. Your physician will refer you to a pain
            management specialist for most chronic pain issues requiring the use of narcotics. After-hour narcotics requests will be denied.</p>
        <p><b><i>Mail order prescriptions:</i></b> Your medical provider will FAX your prescriptions to your mail-order pharmacy. If
            your insurance company requires an additional form to be submitted with your prescription, please tell the provider that you would like
            your prescription printed. </p>
        <p> <b>Patient Dismissal: </b> While we make every effort to work with you, unfortunately sometime we feel it is best for
            all involved to dismiss you from our practice. If you are dismissed from the practice, you will be allowed 15 days for emergency treatment
            in our office. After that time, you will be required to seek the services of another provider in another office. Common reasons for
            dismissal include: Failure to keep appointments, non-compliance with prescribed treatment plan, abusiveness to staff, and failure to pay
            your bill. </p>
        <p> <b>FINANCIAL POLICIES, EFFECTIVE May 1, 2014 </b> </p>
        <p> We accept Cash, Visa, MasterCard, Discover, and Bank Cards for your convenience.</p>
        <p> <b> Private Pay: </b> If you do not have insurance, payment will be due at the time of service. We require payment in
            full at the time of service.</p>
        <p><b>Insurance:</b> Although we are contracted with several insurance companies, <strong>it is your responsibility to make sure
            that our medical providers participates in your specific plan. </strong> If you are unsure, inquire at the front desk prior to being seen. If
            our providers do not participate in your insurance plan, you may still select our office for your medical care; “Out of Network” benefits
            will apply. You may request our office to review a contract from your insurance company; however, you will be responsible for all charges at
            the time of service. If, after review, we choose to contract with your insurance company and benefits apply, you will be reimbursed. <strong>It is
            your responsibility to know your insurance benefits. </strong> Our office will not advise you of your insurance benefits. Please contact your
            insurance company at the Customer Service phone number printed on your insurance card if you have questions pertaining to coverage. You may
            also refer to the coverage book you received from your insurance company.</p>
        <p><b>As a courtesy to our patients, we will file insurance forms from our office. In order to do this, we require all
            information to be completed upon registration. We must have this information prior to your appointment. We request that you update us with
                any changes in your coverage. </b> A photo ID is required at your first visit. If you are unable to provide us with insurance eligibility, you
                will be required to pay for your visit at the time of your appointment. If you provide the correct insurance information to your office
                in a timely manner, we will file a claim on your behalf. We will refund to you any portion that is determined to not be your responsibility. </p>
        <p> <b>New obstetric patients: </b> If you have private insurance or Medicaid coverage for your pregnancy, we will file that
            with insurance for you. <b>If you are currently in the application process for Medicaid you will be required to pay for each visit at the time
            of service until you are approved. You will be reimbursed if your coverage is approved. </b> If you do not have insurance coverage for your
            pregnancy you will need to set up a payment schedule with our office. </p>
        <p> <b>Chiropractic Patients: </b> Dr. Hedlund accepts: <b>Medicare, Nebraska Medicaid,</b> and <b>Arbor</b>.  No other
            insurance will be filed for Chiropractic care. <b>Payment is required at the time of service.</b>  If you have an accident policy we
            will provide you with the information you need to file a claim.</p>
        <p> <b>Co-pays: <i>You are responsible for paying all co-pays at the time of service. </i></b> Co-pays may be paid upon
            check-in or check-out. Co-pays, co-insurance, deductibles and non-covered serviced cannot be waived by our office, as it is a requirement
            placed on you by your insurance carrier. Failure to pay your portion of services rendered will be reported to your insurance company and
            could result in termination of your insurance plan. </p>
        <p> <b> Billing: </b> If you received an invoice from our office for a balance due, it is because that is the balance your
                insurance policy requires you to pay. Please contact your insurance first if you believe there is a problem. The balance on your invoice should
                be equal to the “Patient Responsibility” portion on your Explanation of Benefits (EOB) that you received from your insurance company plus any
                “non-covered services” (less any co-pay that was collected at the time of service). If there is a discrepancy, please contact our office
                immediately to speak with our office manager. You will continue to receive invoices and be subject to collections if you do not advise us
                of discrepancies. Our office may be reached at 402 336-4222. </p>
        <p><b>Collections: </b> Invoices not paid within 90 days begin our collections process. To avoid the collection process
            please contact the office manager toset up a payment schedule. Invoices not paid within 120 days are subject to patient dismissal and
            submission to our Collections Agency and notification to your insurance plan. Non-covered Services: The following are considered
            “Non-Covered Services” by most insurance companies. The fees listed below must be paid at the time of service.
        </p>
            <ul>
                <li> Returned Checks: If your check is returned to us for any reason, you will be charged $30. </li>
                <li> Forms Completion: Disability, Insurance Forms, Travel Forms, Release from Work, Prior Authorizations, and other forms not required
                        by all insurance plans or employers. If you require a provider to complete these forms,<em> at our discretion</em>, there may be
                        a $25 charge in addition to your office visit charge. </li>
                <li> Paper Records: We will provide you, upon written request a paper copy of your medical record. We charge a base fee of $20. </li>
            </ul>
        <p> <b> WELCOME TO ELKHORN VALLEY FAMILY MEDICINE. OUR STAFF AND MEDICAL PROVIDERS LOOK FORWARD TO PROVIDING YOU WITH THE
                BEST CARE POSSIBLE. </b> </p>
    </div>
</template>

<script>
export default {
    name: "Office Policies"
}
</script>

<style scoped>
    div {
        width: 70%;
        margin: auto;
        text-align: left;
    }
</style>